<template>
  <div class="comment-container">
    <div class="inner">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课程评论</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="c-header">
        <div class="c-score">{{ comment.avgScore }}</div>
        <span>满分{{ comment.totalScore }}分</span>
        <div class="c-total">{{ totalPages }}个评论</div>
      </div>
      <div class="c-handle">
        <div class="tab-bar">
          <div class="t-tab">
            <div
              :class="['t-item', { 't-current': index === tabIndex }]"
              v-for="(item, index) in tabList"
              :key="index"
              @click="changeTab(index)"
            >
              {{ item }}
              <div class="t-line"></div>
            </div>
          </div>
        </div>
        <div class="c-wrap">
          <el-select @change="onChooseCourse" clearable v-model="courseId" placeholder="请选择课程名称">
            <el-option
              v-for="(item,index) in courseList"
              :key="index"
              :label="item.courseName"
              :value="item.courseId"
            ></el-option>
          </el-select>
          <div class="search-bar ml20">
            <input
              class="s-input nohighlight"
              style="border:none;"
              type="text"
              placeholder="请输入评论关键词"
              v-model.trim="kw"
              @keyup.enter="onSearchByKw"
              @onkeyup="this.value = this.value.replace(/^ +| +$/g, '')"
            />
            <span class="s-lens" @click="onSearchByKw"></span>
          </div>
        </div>
      </div>
      <div class="data-wrap">
        <div class="comment-list" v-if="!showNoData">
          <div class="c-item" v-for="item in list" :key="item.id">
            <div class="c-top">
              <el-rate :colors="colors" v-model="item.score" disabled></el-rate>
              <div class="c-wrap">
                <span>{{ item.showTime }}</span>
                <span>{{ item.studentNickName }}</span>
              </div>
            </div>
            <div class="c-info">
              <div>
                授课老师:
                <span>{{ item.teacherName }}</span>
              </div>
              <div class="c-line"></div>
              <div>
                课程:
                <span>{{ item.courseName }}</span>
              </div>
            </div>
            <div class="c-content">{{ item.content }}</div>
          </div>
        </div>
        <div v-else class="no-data">
          <div class="n-image"><span>暂无数据</span></div>
        </div>
      </div>
      <el-pagination
        class="page mt50"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="prev, pager, next"
        :total="totalPages"
        :page-size.sync="pageSize"
        @current-change="currentChange"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      colors: ['#FE810A', '#FE810A', '#FE810A'],
      starts: 4,
      // 是否有数据
      showNoData: false,
      tabIndex: 0,
      tabList: ['已展示的评论'],
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 10,
      // 当前页码
      pageIndex: 1,
      kw: '',
      courseId: '',
      list: [],
      comment: {},
      courseList:[]
    };
  },
  computed: {},
  created() {
    this.getList();
    this.getCourseList()
  },
  mounted() {},
  watch: {},
  methods: {
    // 更改 tab
    changeTab(index) {
      this.tabIndex = index;
      this.pageIndex = 1;
    },
    // 通过关键词来搜索
    onSearchByKw() {
      this.getList();
    },
    // 获取评论列表
    getList() {
      this.showNoData = false;
      this.post(
        `/biz/teacher/cmt/courseCmtList?pageNum=${this.pageIndex}&pageSize=${this.pageSize}`,
        { courseId: this.courseId, kw: this.kw },
        e => {
          if (e.code == 200) {
            this.comment = e.extra;
            this.list = e.rows;
            this.totalPages = e.total;
            if (e.total == 0) {
              this.showNoData = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 获取课程列表
    getCourseList() {
      this.showNoData = false;
      this.post(
        `/biz/teacher/course/courseList?pageNum=1&pageSize=100`,
        {
          courseMode: '',
          courseName: '',
          day: '',
          endDay: '',
          startDay: '',
          status: '',
		  teaCmtLook:true
        },
        e => {
          if (e.code == 200) {
            this.courseList = e.rows;
            this.courseList.unshift({ courseId: '', courseName: '全部' })
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 选择课程
    onChooseCourse(value){
      console.log(value)
      this.getList()
    },
    // 当前页变化时执行
    currentChange(e) {
      this.showNoData = false;
      this.pageIndex = e;
      this.getList();
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0
    }
  },
  components: {}
};
</script>

<style>
.el-select input {
  width: 200px;
  height: 32px;
  background: #ffffff;
  border-radius: 10px;
  line-height: 46px;
  color: #89898c;
}
.el-select__caret {
  line-height: 32px;
}
</style>
